import { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { DashContext } from "./Dash";

type ServerListPropsType = {
  servers: any[];
};

const ServerList = ({ servers }: ServerListPropsType) => {
  return (
    <table className="w-full text-left">
      <thead>
        <tr className="text-gray-400">
          <th className="font-normal px-3 pt-0 pb-3 border-b border-gray-800">
            ID
          </th>
          <th className="font-normal px-3 pt-0 pb-3 border-b border-gray-800">
            Date
          </th>
          <th className="font-normal px-3 pt-0 pb-3 border-b border-gray-800"></th>
        </tr>
      </thead>
      <tbody className="text-gray-100">
        {servers.map((server) => {
          const date = moment(server.createdAt);
          const dateDay = date.format("YYYY-MM-DD");
          const dateHour = date.format("h:mm a");

          return (
            <tr key={server.id}>
              <td className="sm:p-3 py-2 px-1 border-b border-gray-800">
                <div className="flex items-center">{server.id}</div>
              </td>
              <td className="sm:p-3 py-2 px-1 border-b border-gray-800">
                <div className="flex items-center">
                  <div className="sm:flex hidden flex-col">
                    {dateDay}
                    <div className="text-gray-400 text-xs">{dateHour}</div>
                  </div>
                </div>
              </td>
              <td className="sm:p-3 py-2 px-1 border-b border-gray-800">
                <Link to={`/dash/servers/${server.id}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Paginator = () => {
  return (
    <div className="flex w-full space-x-2 justify-end">
      <button className="inline-flex items-center h-8 w-8 justify-center text-gray-400 rounded-md shadow border border-gray-800 leading-none">
        <svg
          className="w-4"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
      </button>
      <button className="inline-flex items-center h-8 w-8 justify-center text-gray-500 rounded-md shadow border border-gray-800 leading-none bg-gray-800 text-white">
        1
      </button>
      <button className="inline-flex items-center h-8 w-8 justify-center text-gray-500 rounded-md shadow border border-gray-800 leading-none">
        2
      </button>
      <button className="inline-flex items-center h-8 w-8 justify-center text-gray-500 rounded-md shadow border border-gray-800 leading-none">
        3
      </button>
      <button className="inline-flex items-center h-8 w-8 justify-center text-gray-500 rounded-md shadow border border-gray-800 leading-none">
        4
      </button>
      <button className="inline-flex items-center h-8 w-8 justify-center text-gray-400 rounded-md shadow border border-gray-800 leading-none">
        <svg
          className="w-4"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="9 18 15 12 9 6"></polyline>
        </svg>
      </button>
    </div>
  );
};

const AddFirstServer = () => {
  return (
    <div>
      <div className="max-w-2xl mx-auto bg-gray-800 px-10 py-10 text-center font-normal text-xl">
        <div>You don't have any server configured yet 😔</div>
        <div className="mt-8">
          <Link
            to="/dash/servers/new"
            className="w-full text-jet-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-sunshine-yellow cursor-pointer"
          >
            Add Server
          </Link>
        </div>
      </div>
    </div>
  );
};

const AddServer = () => {
  return (
    <div>
      <Link
        to="/dash/servers/new"
        className="inline-block text-jet-black focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-sunshine-yellow cursor-pointer"
      >
        Add New Server
      </Link>
    </div>
  );
};

export const DashHome = () => {
  const context = useContext(DashContext);

  if (context.servers.length === 0) {
    return <AddFirstServer />;
  } else {
    return (
      <>
        <ServerList servers={context.servers} />
        <div className="flex w-full mt-8">
          <div className="flex-1">
            {context.servers.length > 0 ? <AddServer /> : null}
          </div>
          <div className="flex-1 flex justify-end">
            <Paginator />
          </div>
        </div>
      </>
    );
  }
};
