import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { authHeaders, serverUrl } from "../../config";

type StateType = {
  ramPercentage: number | null;
  cpuPercentage: number | null;
  diskPercentage: number | null;
  uptime: number | null;
  loaded: boolean;
};

const requestOptions = () => ({
  method: "GET",
  headers: authHeaders(),
});

const round = (pct: number) => {
  return Math.round(pct * 100) / 100;
};

const indicatorPct = (map: any, key: string) => {
  const value = map[key];
  const used = value?.used;
  const total = value?.total;

  if (used && total) {
    const pct = used / total;
    return round(pct * 100);
  }

  return null;
};

const indicatorPcAvg = (map: any, key: string) => {
  const items = map[key]?.usage || [];
  const allPcts = items.reduce(
    (acc: number, item: any) => acc + item["percentage"],
    0,
  );

  if (items.length > 0) {
    return round(allPcts / items.length);
  }

  return null;
};

const formatSeconds = (seconds: number | null) => {
  if (seconds === null) {
    return "-";
  }

  const d = moment.duration(seconds, "seconds");

  let results: string[] = [];

  results = addStringToResult(results, d.years(), "y");
  results = addStringToResult(results, d.months(), "mo");
  results = addStringToResult(results, d.days(), "d");
  results = addStringToResult(results, d.hours(), "h");
  results = addStringToResult(results, d.minutes(), "m");

  return results.join(" ");
};

const addStringToResult = (values: string[], value: number, unit: string) => {
  if (value > 0) {
    values.push(`${value}${unit}`);
  }

  return values;
};

const loadServer = async (
  serverId: string,
  setPercentages: Function,
  navigate: NavigateFunction,
) => {
  try {
    const result = await fetch(
      serverUrl(`/api/v1/servers/${serverId}`),
      requestOptions(),
    );
    if (result.status === 200) {
      const body = await result.json();
      const main = body["mainIndicator"];

      const memory = indicatorPct(main, "memory");
      const disk = indicatorPct(main, "disk");
      const cpu = indicatorPcAvg(main, "cpu");
      const uptime = main.uptime?.uptime;

      setPercentages(memory, cpu, disk, uptime);
    } else if ([401, 403].includes(result.status)) {
      localStorage.removeItem("login_token");
      navigate("/dash/login");
    }
  } finally {
  }
};

const MainIndicators = (props: any) => {
  const state = props.state;

  const items = [
    {
      value: state.ramPercentage ? `${state.ramPercentage}%` : "-",
      label: "RAM Usage",
    },
    {
      value: state.cpuPercentage ? `${state.cpuPercentage}%` : "-",
      label: "CPU Usage",
    },
    {
      value: state.diskPercentage ? `${state.diskPercentage}%` : "-",
      label: "Disk Usage",
    },
    {
      value: state.uptime ? formatSeconds(state.uptime) : "-",
      label: "Uptime",
    },
  ];

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
      {items.map((item, index) => {
        return (
          <div
            className="rounded-md border border-gray-700 text-center py-12"
            key={`ind-${index}`}
          >
            <h4 className="text-2xl font-bold text-white">{item.value}</h4>
            <span className="text-sm font-medium block mt-2">{item.label}</span>
          </div>
        );
      })}
    </div>
  );
};

const Notifications = () => {
  const durations = [
    { label: "1m" },
    { label: "5m" },
    { label: "15m" },
    { label: "30m" },
    { label: "1h" },
  ];

  const percentages = [
    { label: "25%" },
    { label: "50%" },
    { label: "75%" },
    { label: "85%" },
    { label: "95%" },
  ];

  const templates = [
    {
      label: "RAM",
    },
    {
      label: "Disk",
    },
    {
      label: "CPU",
    },
  ];

  return (
    <div className="mt-16">
      <h3 className="font-bold text-2xl">Notifications</h3>
      <div className="mt-4 text-lg">
        <form className="mt-4 ml-2">
          When
          <select className="ml-2 mr-2 p-1 bg-gray-800 rounded-md">
            {templates.map((tpl, index) => {
              return <option key={`ntf-tpl-${index}`}>{tpl.label}</option>;
            })}
          </select>
          usage is above
          <select className="ml-2 mr-2 p-1 bg-gray-800 rounded-md">
            {percentages.map((pct, index) => {
              return <option key={`ntf-pct-${index}`}>{pct.label}</option>;
            })}
          </select>
          for
          <select className="ml-2 mr-2 p-1 bg-gray-800 rounded-md">
            {durations.map((dur, index) => {
              return <option key={`ntf-dur-${index}`}>{dur.label}</option>;
            })}
          </select>
          <button className="ml-2 px-6 py-1 bg-gray-800 rounded-md">Add</button>
        </form>
      </div>
    </div>
  );
};

export const ShowServer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [state, setState] = useState<StateType>({
    ramPercentage: null,
    cpuPercentage: null,
    diskPercentage: null,
    uptime: null,
    loaded: false,
  });

  useEffect(() => {
    const setPercentages = (
      ram: number,
      cpu: number,
      disk: number,
      uptime: number,
    ) => {
      setState({
        ...state,
        ramPercentage: ram,
        cpuPercentage: cpu,
        diskPercentage: disk,
        uptime,
        loaded: true,
      });
    };

    if (!state.loaded) {
      loadServer(id || "", setPercentages, navigate);
    }
  }, [state.loaded, id, navigate, state]);

  return (
    <div>
      <MainIndicators state={state} />
      <Notifications />
    </div>
  );
};
