import { createContext, useEffect, useState } from "react";
import { authHeaders, serverUrl } from "../config";
import { Link, NavigateFunction, Outlet, useNavigate } from "react-router-dom";

type StateType = {
  email: null | string;
  servers: any[];
  serversLoaded: boolean;
  serverPrivateToken: null | string;
};

const requestOptions = () => ({
  method: "GET",
  headers: authHeaders(),
});

type ContextType = {
  servers: any[];
  serverPrivateToken: string | null;
  setPrivateServerToken: Function | null;
};

const loadUser = async (setUserData: Function, navigate: NavigateFunction) => {
  try {
    const result = await fetch(serverUrl(`/api/v1/users/me`), requestOptions());

    if (result.status === 200) {
      const body = await result.json();
      setUserData(body.email);
    } else if ([401, 403].includes(result.status)) {
      localStorage.removeItem("login_token");
      navigate("/dash/login");
    }
  } finally {
  }
};

const loadServers = async (setServers: Function) => {
  try {
    const result = await fetch(serverUrl(`/api/v1/servers`), requestOptions());

    if (result.status === 200) {
      const body = await result.json();
      setServers(body);
    }
  } finally {
  }
};

const defaultServerList: ContextType = {
  servers: [],
  serverPrivateToken: null,
  setPrivateServerToken: null,
};
export const DashContext = createContext(defaultServerList);

function Dash(props: any) {
  const navigate = useNavigate();
  const [state, setState] = useState<StateType>({
    email: null,
    servers: [],
    serversLoaded: false,
    serverPrivateToken: null,
  });

  const setUserData = (email: string) => {
    setState({ ...state, email: email });
  };

  const setServers = (servers: any[]) => {
    setState({ ...state, servers: servers, serversLoaded: true });
  };

  const setPrivateServerToken = (token: string) => {
    setState({ ...state, serverPrivateToken: token });
  };

  useEffect(() => {
    if (!state.email) {
      loadUser(setUserData, navigate);
    }

    if (!state.serversLoaded) {
      loadServers(setServers);
    }
  }, [state.email, state.servers]);

  return (
    <div className="bg-gray-900 text-white h-screen flex overflow-hidden text-sm">
      <div className="bg-gray-900 border-gray-800 w-20 flex-shrink-0 border-r flex-col hidden sm:flex">
        <div className="h-16 text-blue-500 flex items-center justify-center text-4xl">
          <Link to="/dash">🦆</Link>
        </div>
        <div className="flex mx-auto flex-grow mt-4 flex-col text-gray-400 space-y-4">
          <button className="h-10 w-12 bg-gray-700 text-white rounded-md flex items-center justify-center">
            <svg
              viewBox="0 0 24 24"
              className="h-5"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
          </button>
          <button className="h-10 w-12 text-gray-500 rounded-md flex items-center justify-center">
            <svg
              viewBox="0 0 24 24"
              className="h-5"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <rect x="3" y="3" width="7" height="7"></rect>
              <rect x="14" y="3" width="7" height="7"></rect>
              <rect x="14" y="14" width="7" height="7"></rect>
              <rect x="3" y="14" width="7" height="7"></rect>
            </svg>
          </button>
        </div>
      </div>
      <div className="flex-grow overflow-hidden h-full flex flex-col">
        <div className="h-16 lg:flex w-full border-b border-gray-800 hidden px-10">
          <div className="flex h-full text-gray-400 py-4">
            <h2 className="text-xl text-white font-bold">
              <Link to="/dash">Servers</Link>
            </h2>
          </div>
          <div className="ml-auto flex items-center space-x-7">
            <button className="flex items-center">
              <span className="ml-2">{state.email}</span>
              <svg
                viewBox="0 0 24 24"
                className="w-4 ml-1 flex-shrink-0"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </button>
          </div>
        </div>
        <div className="flex-grow flex overflow-x-hidden">
          <div className="flex-grow bg-gray-900 overflow-y-auto">
            <div className="sm:p-7 p-4">
              <DashContext.Provider
                value={{
                  servers: state.servers,
                  serverPrivateToken: state.serverPrivateToken,
                  setPrivateServerToken: setPrivateServerToken,
                }}
              >
                <Outlet />
              </DashContext.Provider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dash;
